import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterContentInit, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { debounceTimeAfterFirst } from '@app/_helpers/debounceAfterTime';
import { distinctUntilChangedJson, GROUP_ALIAS_MAP, WithOptional } from '@app/_helpers/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, distinctUntilChanged, map, Observable, startWith } from 'rxjs';
import { firstBy } from 'thenby';
import { UserSettingsQuery } from 'timeghost-api';
type UserGroupSelectValue = {
  users: { id: string; name: string; email?: string }[];
  groups: { id: string; name: string }[];
};
type UserGroupSelectView<T> = {
  entity: T;
  type: 'user' | 'group';
  id: string;
};

const GroupTranslateKeys = GROUP_ALIAS_MAP;
@UntilDestroy()
@Component({
  selector: 'tg-user-group-select-control',
  templateUrl: './user-group-select-control.component.html',
  styleUrls: ['./user-group-select-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserGroupSelectControlComponent),
      multi: true,
    },
  ],
})
export class UserGroupSelectControlComponent implements OnInit, ControlValueAccessor, AfterContentInit {
  @Input()
  filterSearch: (x: UserGroupSelectView<any>) => boolean;
  readonly _search = new UntypedFormControl('');
  readonly _control = new UntypedFormControl({ groups: [], users: [] }, { updateOn: 'change' });
  get control() {
    return this._control;
  }
  get search() {
    return this._search;
  }
  private _required = false;
  @Input()
  set required(val: any) {
    this._required = coerceBooleanProperty(val);
  }
  get required() {
    return this._required;
  }
  propagateChange: Function = () => {};
  propagateTouched: Function = () => {};
  readonly searched$ = combineLatest([
    this._search.valueChanges.pipe(
      startWith(this._search.value),
      debounceTimeAfterFirst(100),
      distinctUntilChanged(),
    ) as Observable<string>,
    this._control.valueChanges.pipe(
      startWith(this._control.value),
      distinctUntilChangedJson(),
    ) as Observable<UserGroupSelectValue>,
    this.userSettingsQuery.select(),
  ]).pipe(
    map(([q, value, user]) => {
      let users = user.workspace.users
          .filter((x) => !x.removed)
          .map((x): UserGroupSelectView<typeof x> => ({ entity: x, type: 'user', id: x.id })),
        groups =
          user.workspace.groups?.map(
            (
              x,
            ): UserGroupSelectView<
              typeof x & WithOptional<{ users: { id: string; name: string; email: string }[] }>
            > => {
              const tName = GroupTranslateKeys[x.id];
              return {
                entity: {
                  ...x,
                  name: tName || x.name,
                  useTranslate: !!tName,
                  users:
                    user.workspace.users
                      ?.filter((u) => x.users?.find((y) => y.id === u.id))
                      .filter((x) => !x.removed)
                      .map(({ id, name, email }) => ({ id, name, email })) || [],
                },
                type: 'group',
                id: x.id!,
              };
            },
          ) || [];
      if (this.filterSearch) (users = users.filter(this.filterSearch)), (groups = groups.filter(this.filterSearch));
      if (value) {
        if (value.users?.length > 0) users = users.filter((x) => value.users.findIndex((y) => y.id === x.id) === -1);
        if (value.groups?.length > 0)
          groups = groups.filter((x) => value.groups.findIndex((y) => y.id === x.id) === -1);
      }
      if (q?.length > 1)
        (users = users.filter(
          (x) => `${x.entity.name || ''}${x.entity.email || ''}`.toLowerCase().indexOf(q.toLowerCase()) !== -1,
        )),
          (groups = groups.filter(
            (x) =>
              (x.entity.useTranslate ? this.translate.instant(x.entity.name) : x.entity.name)
                .toLowerCase()
                .indexOf(q.toLowerCase()) !== -1,
          ));
      return [...users, ...groups]
        .filter(Boolean)
        .sort(
          firstBy<UserGroupSelectView<any>, any>((x) => (x.type === 'group' ? -1 : 0)).thenBy((x) => x.entity?.name),
        );
    }),
  );
  getUsersFromGroup(id: string) {
    const user = this.userSettingsQuery.getValue();
    return user.workspace.groups
      .find((x) => x.id === id)
      ?.users.map((y) => {
        const u = user.workspace.users?.find((u) => u.id === y.id);
        return u && !u.removed;
      })
      .filter(Boolean);
  }
  readonly selected$ = this._control.valueChanges.pipe(
    startWith(this._control.value),
    distinctUntilChangedJson(),
    map((value: UserGroupSelectValue) => {
      if (!value) return [];
      const { groups, users } = value;
      const mapper = <T>(
        x: T & WithOptional<{ id: string }>,
        type: 'user' | 'group',
      ): UserGroupSelectView<typeof x> => {
        const tName = type === 'group' && GroupTranslateKeys[x.id];
        return {
          entity: {
            ...x,
            name: tName || x.name,
            useTranslate: !!tName,
          },
          users: type === 'group' ? this.getUsersFromGroup(x.id) : undefined,
          type,
          id: x.id,
        } as any;
      };
      return [...users?.map((x) => mapper(x, 'user')), ...groups?.map((x) => mapper(x, 'group'))]
        .filter(Boolean)
        .sort(
          firstBy<UserGroupSelectView<any>, any>((x) => (x.type === 'group' ? -1 : 0)).thenBy((x) => x.entity?.name),
        );
    }),
  );

  constructor(
    private userSettingsQuery: UserSettingsQuery,
    private translate: TranslateService,
    private cdref: ChangeDetectorRef,
  ) {}

  registerOnChange(fn: (_: any) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }
  ngOnInit(): void {
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((x) => {
      this.propagateChange(x);
      this.propagateTouched(x);
      this.control.setValue(x, {
        emitEvent: false,
      });
    });
  }
  entityContextMenuPosition = { x: '0px', y: '0px' };
  openEntityContextMenu(event: MouseEvent, trigger: MatMenuTrigger, data: any) {
    event.stopPropagation(), event.preventDefault();
    this.entityContextMenuPosition.x = event.clientX + 'px';
    this.entityContextMenuPosition.y = event.clientY + 'px';
    trigger.menuData = data;
    trigger.menu.focusFirstItem('mouse');
    trigger.openMenu();
  }
  ngAfterContentInit(): void {
    this.cdref.detectChanges();
    this.control.updateValueAndValidity();
  }
  setDisabledState(isDisabled: boolean) {
    if (isDisabled !== this._control.disabled) this._control[isDisabled ? 'disable' : 'enable']();
  }
  get disabled() {
    return this._control.disabled;
  }
  trackId(_: number, { id }: any) {
    return id;
  }
  onOptionSelect(ev: MatAutocompleteSelectedEvent) {
    ev.option.deselect();
    if (!ev.option.value) return;
    const { id, entity, type }: UserGroupSelectView<any> = ev.option.value;
    const value: UserGroupSelectValue = this._control.value;
    if (type === 'user') {
      if (value.users.find((x) => x.id === id)) this.removeEntity(ev.option.value);
      else this.addEntity(ev.option.value);
    } else if (type === 'group') {
      if (value.groups.find((x) => x.id === id)) this.removeEntity(ev.option.value);
      else this.addEntity(ev.option.value);
    }
    this._search.reset('');
  }
  addEntity(entity: UserGroupSelectView<any>) {
    const data = this._control.value as UserGroupSelectValue;
    let users = [...data.users],
      groups = [...data.groups];
    if (entity.type === 'user') users = [...users, entity.entity];
    else if (entity.type === 'group') groups = [...groups, entity.entity];
    this._control.setValue({ users, groups });
  }
  removeEntity(entity: UserGroupSelectView<any>) {
    const data = this._control.value as UserGroupSelectValue;
    let users = [...data.users],
      groups = [...data.groups];
    if (entity.type === 'user') users = users.filter((x) => x.id !== entity.id);
    else if (entity.type === 'group') groups = groups.filter((x) => x.id !== entity.id);
    this._control.setValue({ users, groups });
  }

  writeValue(value: UserGroupSelectValue) {
    this._control.setValue(value, { emitEvent: false });
  }
}
